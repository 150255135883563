export const Logo = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="106"
      height="60"
      viewBox="0 0 48 48"
      fill="#7f56d9"
    >
      <rect width="48" height="48" rx="24" fill="#6941C6" />
      <path
        d="M23.3151 27.176V29.4473H15.6836V18H19.9178V20.2713H18.3268V22.5244H21.6028L21.6028 24.7593H18.3268V27.176H23.3151Z"
        fill="white"
      />
      <path
        d="M5 25.3164L7.33566 25.2289C7.43648 26.7517 8.5287 27.3644 9.83936 27.3644C10.9652 27.3644 11.6709 26.9093 11.6709 26.1741C11.6709 25.439 11.0492 25.2639 10.125 25.0714L8.14222 24.7038C6.46189 24.3713 5.26885 23.3911 5.26885 21.5182C5.26885 19.3478 6.89878 18 9.46968 18C12.3431 18 13.9058 19.4878 13.9562 21.9383L11.6037 22.0083C11.5365 20.713 10.6795 20.0829 9.45288 20.0829C8.37747 20.0829 7.77255 20.538 7.77255 21.2907C7.77255 21.9208 8.24304 22.1483 9.01599 22.3059L10.9988 22.6734C13.1664 23.076 14.1578 24.1962 14.1578 25.9991C14.1578 28.2745 12.259 29.4473 9.83936 29.4473C7.0164 29.4473 5 27.9945 5 25.3164Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M38.6787 18.209H35.8606L32.8223 26.0539H35.4324L37.1559 21.4821H37.2259L40.184 29.2362H42.9495L38.6787 18.209Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M30.1148 21.1476L28.2995 25.963H28.2295L25.2714 18.209H22.5059L26.7767 29.2362H29.5948L32.7275 21.1476H30.1148Z"
        fill="white"
      />
    </svg>
  );
};
