import React from "react";
import {
  Navigate,
  RouterProvider,
  createBrowserRouter,
  useLocation,
} from "react-router-dom";
import { Header } from "src/components/designSystem/organisms/Header";
import { Layout } from "src/components/designSystem/organisms/Layout";
import { Dashboard } from "src/components/pages/Dashboard";
import { Login } from "src/components/pages/Login";
import { NewConnect } from "src/components/pages/NewConnect";
import { Connect } from "src/components/pages/Connect";
import { NewDashboard } from "src/components/pages/NewDashboard";
import { ErrorPage } from "src/components/pages/error";
import { Success } from "src/components/pages/success";

const AuthGate = ({ children }: { children: React.ReactElement }) => {
  const location = useLocation();
  if (localStorage.getItem("token") && location.pathname === "/") {
    return <Navigate replace to={"/dashboard"} />;
  } else if (!localStorage.getItem("token") && location.pathname !== "/") {
    return <Navigate replace to={"/"} />;
  }
  return children;
};

const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <AuthGate>
        <Login />
      </AuthGate>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: "old-dashboard",
    element: (
      <>
        <Header />
        <Dashboard />
      </>
    ),
  },
  {
    path: "dashboard",
    element: (
      <AuthGate>
        <>
          <Header />
          <Layout marginTop="50px">
            <NewDashboard />
          </Layout>
        </>
      </AuthGate>
    ),
  },
  {
    path: "old-connect",
    element: (
      <AuthGate>
        <>
          <Header />
          <Layout>
            <Connect />
          </Layout>
        </>
      </AuthGate>
    ),
  },
  {
    path: "connect",
    element: (
      <AuthGate>
        <>
          <Header />
          <Layout>
            <NewConnect />
          </Layout>
        </>
      </AuthGate>
    ),
  },
  {
    path: "success",
    element: <Success />,
  },
]);

export const Routes: React.FC = () => {
  return (
    <>
      <RouterProvider router={router} />
    </>
  );
};
