import React from "react";
import styles from "./styles.module.css";

export const Loader = () => {
  return (
    <div className={styles.container}>
      <span className={styles.loader}></span>
      <p>Loading this may take a minute</p>
    </div>
  );
};
