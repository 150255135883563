import { Link } from "react-router-dom";
import { cn } from "src/@/lib/utils";
import styles from "./styles.module.css";
import { Logo } from "../../atoms/icons/logo";
// import { TextLogo } from "../../atoms/icons/textLogo";
import { Button } from "src/@/components/ui/button";
import { logout } from "src/api/login";

export const Header = ({
  className,
  ...props
}: React.HTMLAttributes<HTMLElement>) => {
  return (
    <nav className={cn(styles.wrapper, className)} {...props}>
      <div className={styles.header}>
        <div>
          <Link to="/" className={styles.homePageLink}>
            <Logo />
            {/* <TextLogo /> */}
          </Link>
        </div>
        <div className={styles.linksBlockStyle}>
          <Link to="/dashboard" className={styles.linkStyle}>
            Dashboard
          </Link>
          <Link to="/connect" className={styles.linkStyle}>
            Connect
          </Link>
          <Button type="button" variant={"secondary"} onClick={logout}>
            Logout
          </Button>
        </div>
      </div>
    </nav>
  );
};
