import { getUserInfo, updateUserInfo } from "src/api/login";
import styles from "./styles.module.css";
import { addPage } from "src/api/pages";
import { getFBConversations } from "src/utils/FbApiHandlers";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";

export const NewConnect = () => {
  const [isLogin, setisLogin] = useState(false);
  const [userDetail, setUserDetail] = useState<{
    _id: string;
    accessToken: string;
    name: string;
    instagramId: string;
    password: string;
    username: string;
    createdAt: string;
    updatedAt: string;
  }>({
    _id: "",
    accessToken: "",
    name: "",
    instagramId: "",
    password: "",
    username: "",
    createdAt: "",
    updatedAt: "",
  });

  const [loggedInUser, setloggedInUser] = useState("");

  const getUserPageInfo = () =>
    getUserInfo().then((res: any) => {
      if (res) {
        setUserDetail(res);
        if (res?.accessToken?.length) {
          if (typeof FB !== "undefined" && typeof FB === "object") {
            FB.getLoginStatus((response) => {
              if (response.status === "connected") {
                setisLogin(true);
                FB.api(`/${res.instagramId}/`, async (userResponse: any) => {
                  // @ts-ignore
                  if (userResponse && !userResponse.error) {
                    setloggedInUser(userResponse?.name);
                  }
                });
              }
            });
          }
        }
      }
    });

  useEffect(() => {
    getUserPageInfo();
  }, []);

  const handleFacebookLogout = async () => {
    if (typeof FB !== "undefined" && typeof FB === "object") {
      FB.logout((response) => {
        setisLogin(false);
        updateUserInfo({
          accessToken: "",
          name: userDetail?.name,
          instagramId: userDetail?.instagramId,
        }).then(() => {
          setUserDetail({
            ...userDetail,
            accessToken: "",
          });
          toast.success("Logged out successfully");
        });

      });
    }
  };

  const handleFacebookLogin = () => {
    try {
      if (typeof FB !== "undefined" && typeof FB === "object") {
        FB.login(
          (response) => {
            if (response.authResponse) {
              FB.api(
                "/oauth/access_token",
                {
                  client_id: "1040993306867419",
                  client_secret: "f7e4166bc1ceec08b561e4ed0234149b",
                  grant_type: "fb_exchange_token",
                  fb_exchange_token: response.authResponse.accessToken,
                },
                (res) => {
                  const token = (res as { access_token: string }).access_token;
                  FB.api(
                    `/${response.authResponse.userID}/`,
                    (userResponse: any) => {
                      // @ts-ignore
                      if (userResponse && !userResponse.error) {
                        updateUserInfo({
                          accessToken: token,
                          name: userResponse?.name,
                          instagramId: userResponse?.id,
                        }).then(() => {
                          setisLogin(true);
                          setloggedInUser(userResponse?.name);
                          toast.success("Logged in successfully");
                          toast.success("We are fetching your Instagram account details. This may take a few minutes.");
                        });
                      }
                    }
                  );
                }
              );

              // FB.api("me/accounts?platform=instagram", (pages: any) => {
              //   // const page = pages.data.find((x: any) => x.name === "Hey Seva");
              //   const page = pages.data[0];
              //   FB.api(
              //     `${page.id}?fields=connected_instagram_account,name,category,access_token,picture{url},followers_count,fan_count,about`,
              //     "get",
              //     {
              //       access_token: page.access_token,
              //     },
              //     // "me/accounts?fields=connected_instagram_account,name,category,access_token",
              //     (res: any) => {
              //       console.log("pages---", res);
              //       [res].forEach(async (page: any) => {
              //         FB.api(
              //           `${page.connected_instagram_account.id}?fields=follows_count,username,followers_count,biography,profile_picture_url`,
              //           "get",
              //           {
              //             access_token: page.access_token,
              //           },
              //           async (profile: any) => {
              //             const pageRes = await addPage({
              //               accessToken: page.access_token,
              //               category: page.category,
              //               name: page.name,
              //               pageId: page.id,
              //               connectedId: page.connected_instagram_account.id,
              //               followers: `${profile.followers_count}`,
              //               following: `${profile.follows_count}`,
              //               about: profile.biography,
              //               picture: profile.profile_picture_url,
              //               username: profile.username,
              //             });
              //             await getFBConversations(
              //               page.id,
              //               page.access_token,
              //               pageRes?.data?._id
              //             );
              //           }
              //         );

              //         getUserPageInfo();
              //       });
              //     }
              //   );
              // });
            } else {
              console.log("Facebook Login canceled or not authorized.");
            }
          },
          {
            scope:
              // "instagram_basic,instagram_manage_insights,instagram_manage_messages,pages_show_list,read_insights,email,pages_manage_metadata,pages_messaging,pages_read_engagement",
              // "instagram_basic,instagram_manage_messages,pages_show_list,instagram_manage_comments,pages_read_engagement",
              // "instagram_basic,instagram_manage_messages,pages_show_list",
              "instagram_basic,instagram_manage_messages,business_management,pages_show_list,instagram_manage_insights,instagram_manage_comments,pages_manage_metadata,pages_read_engagement,pages_messaging",

            // "pages_messaging,instagram_basic,instagram_manage_comments,instagram_manage_insights,instagram_manage_messages,pages_read_engagement,pages_read_user_content",
          }
        );
      } else {
        console.log("Facebook SDK not loaded.");
      }
    } catch (error) {
      console.log("fb login error", error);
    }
  };
  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        <div className={styles.cardBlock}>
          <div className={styles.cardHeaderBlock}>
            {userDetail.accessToken.length && isLogin ? (
              <p className={styles.cardHeaderBlockTitle}>
                Connected with Instagram @{loggedInUser}
              </p>
            ) : (
              <p className={styles.cardHeaderBlockTitle}>
                Connect with Instagram
              </p>
            )}
          </div>
          <div className={styles.cardBodyBlock}>
            {isLogin ? (
              <button
                className={styles.primaryButton}
                onClick={handleFacebookLogout}
              >
                Disconnect
              </button>
            ) : (
              <button
                className={styles.primaryButton}
                onClick={handleFacebookLogin}
              >
                Continue with Facebook
              </button>
            )}
          </div>
          {!isLogin && (
            <div className={styles.cardFooterBlock}>
              <h4 className={styles.cardFooterTitleStyle}>
                SEVA needs permission from Instagram to:
              </h4>
              <ul className={styles.cardFooterListStyle}>
                <li>View basic information about your business account</li>
                <li>View your mentions in stories via messages </li>
              </ul>
              <h4 className={styles.cardFooterTitleStyle}>
                Make sure you have completed these steps:
              </h4>
              <ul className={styles.cardFooterListStyle}>
                <li>
                  Converted your account to a Business Account in the instagram
                  app.
                  <Link
                    to="https://help.instagram.com/502981923235522"
                    className={styles.linkStyle}
                  >
                    Learn more
                  </Link>
                </li>
                <li>
                  Attached your Facebook Page to your Instagram Business
                  Account.
                  <Link
                    to="https://help.instagram.com/570895513091465"
                    className={styles.linkStyle}
                  >
                    Learn more
                  </Link>
                </li>
                <li>
                  Either own the Facebook Page, or have admin access granted to
                  you through Facebook Page or Meta Business Manager. For help
                  contact us via <span>support@heyseva.com</span> for detailed
                  guidance.
                </li>
              </ul>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
