// ChatBox.tsx
import classNames from "classnames";
import React, { useCallback, useEffect, useState } from "react";
import styles from "./styles.module.css";
import { SendIcon } from "../../atoms/icons/sendIcon";
import {
  // addCreator,
  getCreators,
} from "src/api/creators";
import { getFBCreatorConversation } from "src/api/conversations";
import { Refresh } from "../../atoms/icons/refresh";
import { sendMessage } from "src/utils/FbApiHandlers";
import { toast } from "react-toastify";
// import { getPages } from "src/api/pages";
import moment from "moment";
// import io from "socket.io-client";

const socket = new WebSocket("wss://user.api.heyseva.com");

// interface Message {
//   id: number;
//   message: string;
//   sender: string;
//   messageType: string;
//   media?: string;
//   messageCreatedAt: string;
//   from?: {
//     id: string;
//     username: string;
//     creatorId: string | null;
//     pageId: string | null;
//   };
//   to?: {
//     id: string;
//     username: string;
//     creatorId: string | null;
//     pageId: string | null;
//   };
// }

interface NewMessage {
  isSeen?: boolean;
  story?: {
    mention: {
      link: string;
    };
  };
  message: string;
  created_time: string;
  from: {
    username: string;
    id: string;
  };
  to: {
    data: [
      {
        username: string;
        id: string;
      }
    ];
  };
  id: string;
  attachments?: {
    data: [
      {
        image_data: {
          width: number;
          height: number;
          max_width: number;
          max_height: number;
          url: string;
          preview_url: string;
        };
      }
    ];
  };
}

interface AppUser {
  _id: string;
  name: string;
  username: string;
  socialId: string;
  messageScopeId: string;
  igId: string;
  profilePic: string;
  followers: number;
}

type Props = {
  selectedUserName: string;
  selectPage: any;
  message: string;
};

export const ChatBox: React.FC<Props> = ({
  selectedUserName,
  selectPage,
  message = "",
}) => {
  const [refreshLoading, setRefreshLoading] = useState(false);
  // const [selectPage, setSelectPage] = useState({
  //   _id: "",
  //   name: "",
  //   pageId: "",
  //   accessToken: "",
  //   instagramId: "",
  //   password: "",
  //   username: "",
  //   createdAt: "",
  //   updatedAt: "",
  // });
  const [users, setUsers] = useState<AppUser[]>([]);
  const [selectedUser, setSelectedUser] = useState<AppUser>();

  const getPageConversation = (selectedUser: AppUser) => {
    if (selectedUser) {
      setRefreshLoading(true);
      getFBCreatorConversation({
        token: selectPage.accessToken,
        pageId: selectPage.pageId,
      })
        .then(async (response: any) => {
          const res: any = response.data.find(
            (x: any) =>
              x.name.trim().toLowerCase() ===
              selectedUser?.name.trim().toLowerCase()
          ).messages.data;
          if (res) {
            setMessages(res.reverse());

            if (selectedUser.messageScopeId.length === 0) {
              if (res[0].from.username === selectedUser.username) {
                selectedUser.messageScopeId = res[0].from.id;
              } else {
                selectedUser.messageScopeId = res[0].to.data[0].id;
              }
              // await addCreator({
              //   name: selectedUser.name,
              //   username: selectedUser.username,
              //   products: "",
              //   date: "",
              //   followers: selectedUser.followers,
              //   profilePic: selectedUser.profilePic,
              //   id: selectedUser._id,
              //   ig_id: selectedUser.igId,
              //   messageScopeId: selectedUser.messageScopeId,
              // });
              setSelectedUser({ ...selectedUser });
            }

            if (res.length > 4) {
              setTimeout(() => {
                const chatBoxView = document.getElementById("chatBoxView");
                if (chatBoxView) {
                  chatBoxView.scrollTop = chatBoxView.scrollHeight;
                }
              }, 500);
            }
          }
        })
        .finally(() => {
          setRefreshLoading(false);
        });
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const getPageCreators = () => {
    getCreators().then((res: any) => {
      if (res) {
        setUsers(res.data.filter((x: any) => x.username?.length > 0));
        if (selectedUserName) {
          const matchingCreator = res.data.find(
            (x: any) => x.username === selectedUserName
          );
          if (matchingCreator) {
            setSelectedUser(matchingCreator);
            setTimeout(() => {
              getPageConversation(matchingCreator);
            }, 1000);
            // getPageConversation();
          }
        }
      }
    });
  };

  useEffect(() => {
    if (!selectedUser?._id) {
      getPageCreators();
      // getPages().then((res: any) => {
      //   if (res) {
      //     setSelectPage(res.data[0]);
      //   }
      // });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedUser]);

  const [messageInput, setMessageInput] = useState<string>(message ?? "");
  const [messages, setMessages] = useState<NewMessage[]>([]);

  const handleUserClick = async (user: AppUser) => {
    await setSelectedUser(user);
    setTimeout(() => {
      getPageConversation(user);
    }, 1000);
  };

  const handleSendMessage = () => {
    // Send the message to your backend or update state as needed
    if (messageInput.trim().length > 0) {
      setMessageInput("");
      sendMessage({
        page_id: selectPage._id,
        pageId: selectPage.pageId,
        to:
          users.find((x) => x._id === selectedUser?._id)?.messageScopeId || "",
        username: getUserName(selectedUser?._id || "") || "",
        pageName: selectPage.username,
        message: messageInput,
        token: selectPage.accessToken,
        callback: (status) => {
          if (status) {
            getPageConversation(selectedUser as AppUser);
            toast.success("Message sent successfully");
          } else {
            toast.error("Message sending failed, please authenticate again");
          }
        },
      });
    }
  };

  const getUserName = useCallback(
    (creatorId: string) => {
      return users.find((x) => x._id === creatorId)?.username;
    },
    [users]
  );

  useEffect(() => {
    socket.onopen = () => {
      console.log("Connected to WebSocket server");
    };

    socket.onmessage = (event) => {
      const message = event.data;
      console.log("Message from server:", message);
      setMessages((prevMessages) => [...prevMessages, message]);
    };

    socket.onclose = () => {
      console.log("Disconnected from WebSocket server");
    };

    socket.onerror = (error) => {
      console.error(`WebSocket error: ${error}`);
    };

    // return () => {
    //   socket.close();
    // };
  }, [messages]);

  return (
    <div className={classNames("flex", styles.container)}>
      <div className={classNames("w-1/4", styles.sideMenu)}>
        <div className="flex border-b">
          <h2 className={classNames("p-4 ", styles.sideMenuTitle)}>
            Conversations
          </h2>
          <div className="flex items-center">
            <p className={styles.sideMenuBadge}>{users.length}</p>
          </div>
        </div>
        <ul>
          {users.reverse().map((user, index) => (
            <li
              key={index}
              className={classNames(
                `cursor-pointer p-4 border-b flex justify-between`,
                styles.userName,
                {
                  [styles.selectedUser]: selectedUser?._id === user._id,
                }
              )}
              onClick={() => handleUserClick(user)}
            >
              {user.username}
            </li>
          ))}
        </ul>
      </div>

      <div className="flex-1 bg-white">
        {selectedUser && (
          <div
            className={classNames(
              "flex items-center justify-between p-4 mb-4",
              styles.chatBoxHeader
            )}
          >
            <h2 className={styles.chatBoxHeaderTitle}>
              {getUserName(selectedUser._id)}
            </h2>
            <button
              className={classNames(styles.refresh, {
                [styles.loading]: refreshLoading,
              })}
              onClick={() => getPageConversation(selectedUser)}
            >
              <Refresh className={styles.icon} />
            </button>
          </div>
        )}

        <div
          className={classNames(
            "flex flex-col space-y-2 p-4",
            styles.chatBoxView
          )}
          id="chatBoxView"
        >
          {messages.map((msg, i) => (
            <>
              {msg.story?.mention?.link?.length !== undefined ||
              msg?.attachments?.data?.length ||
              msg?.message?.length !== 0 ? (
                <div
                  key={i}
                  className={classNames({
                    "self-start": msg.from?.id === selectedUser?.messageScopeId,
                    "self-end": msg.from?.id !== selectedUser?.messageScopeId,
                  })}
                >
                  {msg.from?.id === selectedUser?.messageScopeId && (
                    <>
                      <p className={styles.chatBoxUserName}>
                        {getUserName(selectedUser?._id || "")}
                        <span className={styles.chatBoxUserDate}>
                          {moment(msg.created_time).format("DD/MM/YY hh:mm:a")}
                        </span>
                      </p>
                    </>
                  )}
                  {msg.from?.id !== selectedUser?.messageScopeId && (
                    <p className={styles.chatBoxUserName}>
                      You
                      <span className={styles.chatBoxUserDate}>
                        {moment(msg.created_time).format("DD/MM/YY hh:mm:a")}
                      </span>
                    </p>
                  )}
                  <div
                    key={msg.id}
                    className={classNames("py-2 px-4 rounded-full max-w-md", {
                      [styles.primaryChatBox]:
                        msg.from?.id === selectedUser?.messageScopeId,
                      [styles.secondaryChatBox]:
                        msg.from?.id !== selectedUser?.messageScopeId,
                    })}
                  >
                    {msg.story?.mention?.link.length !== undefined ||
                    msg?.attachments?.data ? (
                      <a
                        href={
                          msg.story?.mention?.link ||
                          msg?.attachments?.data?.at(0)?.image_data?.url
                        }
                        target="_blank"
                        rel="noreferrer"
                        title={"media"}
                      >
                        {msg.story?.mention.link ? (
                          <img
                            title={"Story"}
                            src={msg.story?.mention.link}
                            width={msg.story?.mention.link ? 30 : 100}
                            height={msg.story?.mention.link ? 80 : 250}
                            alt={"media"}
                          />
                        ) : null}
                        {msg?.attachments?.data?.at(0)?.image_data
                          ?.preview_url ? (
                          <img
                            title={"Image"}
                            src={
                              msg?.attachments?.data?.at(0)?.image_data
                                ?.preview_url
                            }
                            width={msg.story?.mention.link ? 30 : 100}
                            height={msg.story?.mention.link ? 80 : 250}
                            alt={"media"}
                          />
                        ) : null}
                        {msg.story?.mention.link.length === 0
                          ? "Mentioned you in a story"
                          : ""}
                        {msg.message ? msg.message : ""}
                      </a>
                    ) : (
                      msg.message
                    )}
                  </div>
                  {msg.from?.id !== selectedUser?.messageScopeId &&
                    !!msg.isSeen && (
                      <span className={styles.seenText}>Seen</span>
                    )}
                </div>
              ) : null}
            </>
          ))}
        </div>

        {/* Message Input */}
        {selectedUser?.messageScopeId.length !== 0 ? (
          <div
            className={classNames(
              "flex items-center p-4 mt-4",
              styles.chatBoxFooter
            )}
          >
            <input
              type="text"
              placeholder="Message"
              value={messageInput}
              onChange={(e) => setMessageInput(e.target.value)}
              className={classNames(
                "flex-1 p-2 mr-2 border",
                styles.sendMessageInput
              )}
            />
            <button
              className={classNames("px-4 py-2 text-white", styles.sendButton)}
              onClick={handleSendMessage}
            >
              <SendIcon />
            </button>
          </div>
        ) : (
          <div>
            <p
              style={{
                textAlign: "center",
                color: "gray",
                padding: "10px",
                borderTop: "1px solid #80808059",
              }}
            >
              Cannot send message to this user, initiate message from the
              instagram app.{" "}
            </p>
          </div>
        )}
      </div>
    </div>
  );
};
